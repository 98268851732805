import React, { useState } from "react"
import Media from "../retech/components/MediaCenter/Index"
import Layout from "../retech/components/layout/Layout"
import { Card, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import { Link, graphql } from "gatsby"
import CarouselTimeline from "../retech/components/Timeline/CarouselTimeline"
import { Helmet, Parent, Child } from "react-helmet"
import CarouselValue from "../retech/components/Value/CarouselValue"

export default function CompanyPage({ data }) {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const Hero = data.Hero.nodes
  const Services = data.Services.nodes
  const History = data.History.nodes
  const missionhero = data.missionhero.nodes
  const Widget1 = data.Widget1.nodes
  const Widget2 = data.Widget2.nodes
  const president = data.president.nodes
  const Team = data.Team.nodes
  const contactlocation = data.contactlocation.nodes
  const [showTeam, setTeam] = useState("Managers")
  const [InfoTeam, setInfoTeam] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const valueshero = data.valueshero.nodes

  return (
    <Layout>
      <Helmet>
        <title>Retech | Company</title>

        <meta
          name="description"
          content={data.metadata.nodes[0].CompanyMetaDescription}
        />
        <meta
          name="keywords"
          content={data.metadata.nodes[0].Companykeywords}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].CompanyMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].CompanyMetaDescription}
        />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].CompanyMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].CompanyMetaDescription}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      {Team.map((node, index) => {
        return (
          <>
            <Modal size="xl" show={show} onHide={handleClose}>
              <Modal.Header closeButton className="modal-team">
                <div className="row d-none d-sm-flex">
                  <div className="col-xl-6 col-lg-6 col-md-12 mb-md-4 mb-xs-4">
                    <img
                      src={InfoTeam.MemberImageDetail}
                      alt="image model"
                      className="w-100"
                      width="412.23px"
                      height="656.17px"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <h4 className="font-weight-bold color-dir">
                      {InfoTeam.MemberTitle}
                    </h4>
                    <h4>{InfoTeam.MemberRole}</h4>
                    <p className="product-par">
                      <small>{InfoTeam.MemberDescriptionDetail}</small>
                    </p>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="d-block d-sm-none modal-team">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 mb-md-4 mb-xs-4">
                    <img
                      src={InfoTeam.MemberImageDetail}
                      alt="image model"
                      className="w-100"
                      width="412.23px"
                      height="656.17px"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <h4 className="font-weight-bold color-dir">
                      {InfoTeam.MemberTitle}
                    </h4>
                    <h4>{InfoTeam.MemberRole}</h4>
                    <p className="product-par">
                      <small>{InfoTeam.MemberDescriptionDetail}</small>
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )
      })}

      <div className="container-fluid px-0 mb-5">
        {Hero.map((node, index) => {
          return node.HeroCompany
            ? node.HeroCompany.map(q => (
              <>
                <div
                  className="show_bg_desktop d-none d-sm-block"
                  style={{
                    backgroundImage:
                      " linear-gradient(349.66deg, #1F2025 4.11%, rgba(31, 32, 37, 0.3) 18.52%, rgba(31, 32, 37, 0) 36.03%, rgba(31, 32, 37, 0) 70.01%),url(" +
                      q.HeroSectionImage +
                      ")",
                    height: "523px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="container pt-5">
                    <div className="row pt-5">
                      <div className="col-md-8 pt-5">
                        <h1 className="font-wight-boder text-white mb-5">
                          {q.CompanySectionTitle}
                        </h1>
                        <p className="text-white product-par pr-5 mr-5 w-75">
                          {q.CompanySectiondescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
            : null
        })}

        {Hero.map((node, index) => {
          return node.HeroCompany
            ? node.HeroCompany.map(q => (
              <>
                <div
                  className="show_bg_desktop d-block d-sm-none"
                  style={{
                    backgroundImage:
                      "linear-gradient(349.66deg, #000000 4.11%, rgba(0, 0, 0, 0) 70.01%),url(" +
                      q.HeroSectionImage +
                      ")",
                    backgroundSize: "cover",
                    height: "500px",
                  }}
                >
                  <div className="container  pt-5">
                    <div className="row">
                      <div className="col-md-5">
                        <h3 className="font-wight-boder mb-5">
                          {q.CompanySectionTitle}
                        </h3>
                        <p className="text-white product-par pr-5">
                          <small>{q.CompanySectiondescription}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
            : null
        })}
      </div>
      <div className="container faq">
        {Services.map((node, index) => {
          return (
            <>
              <div className="col-md-12 border-bottom pb-5" key={index}>
                <div className="row pt-3 pb-1">
                  <div className="col-md-10 mx-auto text-center">
                    <div className="parallelogram mb-4 mx-auto">
                      <Link to="#" className="text-dark">
                        History
                      </Link>
                    </div>
                    <h3 className="mb-4 font-weight-bolder">
                      {node.ServiceCompanyWidgetTitle}
                    </h3>
                    <p className="text-muted">
                      {" "}
                      {node.ServiceCompanyeWidgetDescription}
                    </p>
                    <Link
                      to={node.ServiceCompanyWidgetCTALink}
                      className="font-weight-bolder see-all justify-content-center"
                    >
                      <p> {node.ServiceCompanyeWidgetCTAText}</p>
                      <FontAwesomeIcon
                        className="ml-3"
                        icon={faChevronCircleRight}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )
        })}
      </div>
      <div className="container-fluid bg-light">
        <div className="container faq  pr-xs-0 pl-xs-0">
          <div className="col-md-12 pt-5 pb-5  pr-xs-0 pl-xs-0 ">
            <div className="row">
              <div className="col-md-12 d-none d-sm-block">
                <div className="line">
                  {/*
                  <div className="circle"></div>
                  <div className="circle-2"></div>
                  */}
                </div>
              </div>
              <div className="col-md-6">
                {History.map((node, index) => {
                  return (
                    <>
                      <Card className="border-0 p-4" key={index}>
                        <Card.Body>
                          <h4 className="font-weight-bold mb-4">
                            {node.historyTitle}
                          </h4>
                          <h4 className="font-weight-bold mb-4 careers-second-par">
                            {node.historyIntro}
                          </h4>
                          <p className="product-par">
                            {node.historyDescription}
                          </p>
                        </Card.Body>{" "}
                      </Card>
                    </>
                  )
                })}
              </div>
              <div className="col-md-12 d-block d-sm-none pr-xs-0 pl-xs-0">
                <div className="line">
                  {/*<div className="circle"></div>*/}
                </div>
              </div>
              <div
                className="col-md-6 Product  pr-xs-0 pl-xs-0"
                style={{ alignSelf: "center" }}
              >
                <CarouselTimeline />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-0 py-5">
        <div className="col-md-12 px-0">
          {president.map((node, index) => {
            return (
              <>
                <Card className="bg-light py-4">
                  <Card.Body>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <img
                          src={node.PresidentBioImage}
                          className="w-100"
                          alt="prisedent"
                        />
                      </div>
                      <div className="col-md-6">
                        <h4 className="font-weight-bold color-dir">
                          {node.PresidentBioTitle}
                        </h4>
                        <h4>{node.PresidentBioRole}</h4>
                        <p className="product-par">
                          <small>{node.PresidentBioDescription}</small>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )
          })}
        </div>
      </div>
      <div className="container" id={"team"}>
        {Team.map((node, index) => {
          return (
            <>
              <div className="col-md-6 mx-auto text-center" key={index}>
                <h2 className="font-weight-bold">{node.TeamTitle}</h2>
                <p className="product-par">{node.TeamIntro}</p>
              </div>
            </>
          )
        })}

        <div className="col-md-12">
          <div className="row  filter-button">
            {Team.map((node, index) => {
              return (
                <>
                  {node?.Members?.length > 0 &&
                    DistinctRecords(node.Members, "MemberCategory").map(
                      node2 => (
                        <div
                          className={`parallelogram mb-4 mr-3 ${showTeam === node2.MemberCategory
                            ? "active-filter"
                            : ""
                            }`}
                          style={{ width: "max-content" }}
                          onClick={() => {
                            setTeam(node2.MemberCategory)
                          }}
                        >
                          <Link className="text-dark ">
                            {node2.MemberCategory}
                          </Link>
                        </div>
                      )
                    )}
                </>
              )
            })}
          </div>
        </div>
        {Team.map((node, index) => {
          return (
            <>
              <div className="col-md-12 text-center mx-auto py-4">
                <div className="row">
                  {node?.Members?.length > 0 &&
                    node.Members?.map(node2 => (
                      <>
                        {showTeam === node2.MemberCategory ? (
                          <div className="col-xl-4 col-lg-4 col-md-4 mb-4">
                            <div className="circle-team text-center">
                              <img src={node2.MemberImage} onClick={() => {
                                setInfoTeam(node2)
                                handleShow()
                              }} alt="team-1" />
                              <h4 className="font-weight-bold mt-3" onClick={() => {
                                setInfoTeam(node2)
                                handleShow()
                              }}>
                                {node2.MemberTitle}
                              </h4>
                              <p className="product-par">{node2.MemberRole}</p>
                              <div
                                onClick={() => {
                                  setInfoTeam(node2)
                                  handleShow()
                                }}
                              >
                                <Link className="font-weight-bolder see-all justify-content-center">
                                  View Bio
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                </div>
              </div>
            </>
          )
        })}
        {/* {showTeam === "Product" ? (
                    <div className="col-md-9 mx-auto py-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {showTeam === "Hr" ? (
                    <div className="col-md-9 mx-auto py-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    {" "}
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="circle-team  text-center ">
                                    <img alt="" />
                                    <h4 className="font-weight-bold mt-3">John McKellar</h4>
                                    <p className="product-par">Title Here</p>
                                    <div onClick={handleShow}>
                                        <Link className="font-weight-bolder see-all">View Bio</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )} */}


        <div className="container faq" style={{ marginBottom: '17rem' }}>
          <div className="col-md-12 pt-4 pb-5">
            {missionhero.map((node, index) => {
              return (
                <>
                  <div className="row" key={index}>
                    <div className="col-md-6">
                      <h4 className="font-weight-bold mb-4">
                        {node.MissioneWidgetTitle}
                      </h4>
                      <h4 className="font-weight-bold mb-4 careers-second-par">
                        {node.MissioneWidgetintro}
                      </h4>
                      <p className="product-par">
                        {node.MissionWidgetDescription}
                      </p>
                    </div>
                    <div className="col-md-6 pl-0 carrers-rectangle" style={{
                      backgroundImage:
                        "url(" +
                        node.MissionWidgetVisionImage +
                        ")"

                    }}>
                      <div className="col-md-10  py-5 px-4 details">
                        <div className="row">
                          <div className="col-md-12 py-3">
                            <h4 className="font-wight-boder text-white mb-4">
                              {node.MissionWidgetImageTitle}
                            </h4>
                            <p className="text-white font-weight-light product-par pr-0">
                              {node.MissionWidgetImageDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
      {valueshero.map((node, index) => {
        return (
          <>
            <div className="container-fluid bg-light" key={index}>
              <div className="container py-5">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8 py-5 mt-2">
                      <h3 className="font-weight-bold">
                        {node.valuesWidgetTitle}
                      </h3>
                      <p className="product-par">{node.valuesWidgetIntro}</p>
                    </div>
                  </div>
                  <div className="row careers-carousel">
                    <CarouselValue />
                    <div className="col-md-12 text-center mt-5">
                      <Link
                        to={node.valuesWidgetCTALink}
                        className="font-weight-bolder see-all"
                      >
                        <p> {node.valuesWidgetCTAText} </p>
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}

      <div className="container" >
        <div className="container">
          <div className="col-md-12">
            <div className="row py-5">
              {Widget1.map((node, index) => {
                return (
                  <>
                    <div className="col-md-6 mb-4">
                      <img
                        src={node.CompanyTextwithImageWidget1TitleImage}
                        className="w-100"
                        alt="section 1"
                      />
                    </div>
                    <div className="col-md-6">
                      <h3 className="font-weight-bold">
                        {node.CompanyTextwithImageWidget1Title}
                      </h3>
                      <p className="product-par">
                        {node.CompanyTextwithImageWidget1TitleDescription}
                      </p>
                      <Link
                        to={node.CompanyTextwithImageWidget1TitleCTALink}
                        className="font-weight-bolder see-all"
                      >
                        <p>{node.CompanyTextwithImageWidget1TitlCTAText}</p>
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Link>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="row py-5">
              {Widget2.map((node, index) => {
                return (
                  <>
                    <div className="col-md-6 mb-4">
                      <h3 className="font-weight-bold">
                        {node.CompanyTextwithImageWidget2Title}
                      </h3>
                      <p className="product-par">
                        {node.CompanyTextwithImageWidget2TitleDescription}
                      </p>
                      <Link
                        to={node.CompanyTextwithImageWidget2TitleCTALink}
                        className="font-weight-bolder see-all"
                      >
                        <p>{node.CompanyTextwithImageWidget2TitlCTAText}</p>
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={node.CompanyTextwithImageWidget2TitleImage}
                        className="w-100"
                        alt="section 1"
                      />
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row py-5">
              <div className="col-md-10 mx-auto text-center mb-3">
                <h3 className="mb-4 font-weight-bolder">Retech Locations</h3>
              </div>
            </div>
          </div>
          <div className="col-md-12 d-none d-sm-block">
            <div className="row pt-4 pb-5 mb-5 locationcareer">
              {contactlocation.map((node, index) => {
                return (
                  <>
                    {node?.location?.length > 0 &&
                      node.location.map(node2 => (
                        <div className="col-xl-3 col-lg-3 text-center" key={index}>
                          <img src={node2.locationImage} alt="buffalo" />
                        </div>
                      ))}
                  </>
                )
              })}

              {contactlocation.map((node, index) => {
                return (
                  <>
                    {node?.location?.length > 0 &&
                      node.location.map((node2, index) => (
                        <div
                          className="col-xl-3 col-lg-3 text-center border-right"
                          key={index}
                        >
                          <h4 className="mb-2 font-weight-lighter">
                            {node2.locationTitle}
                          </h4>
                          <p className="text-muted mb-1">
                            <small> {node2.locationAddres1}</small>
                          </p>
                          <p className="text-muted mb-1">
                            <small>{node2.locationAddres2}</small>
                          </p>
                          <p className="text-muted mb-1">
                            <small>
                              Phone:{" "}
                              <a href={"tel:" + node2.locationPhone}>
                                {node2.locationPhone}
                              </a>
                            </small>
                          </p>
                          <p className="text-muted mb-1">
                            <small>
                              {" "}
                              Email:{" "}
                              <a href={"mailto:" + node2.locationEmail}>
                                {node2.locationEmail}
                              </a>
                            </small>
                          </p>
                          <div className="d-flex justify-content-center mt-3">
                            <a href={node2.locationCTALink} target="_blank">
                              <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                                {node2.locationCTAText}
                              </h6>
                            </a>
                            <img src="/img/arrow.svg" alt="arrow" />
                          </div>
                        </div>
                      ))}
                  </>
                )
              })}
            </div>
          </div>
          <div className="col-md-12 d-block d-sm-none">
            <div className="row pt-4 pb-5 mb-5">
              {contactlocation.map((node, index) => {
                return (
                  <>
                    {node?.location?.length > 0 &&
                      node.location.map(node2 => (
                        <div
                          className="col-md-4 text-center border-bottom mb-4 pb-4"
                          key={index}
                        >
                          <img
                            src={node2.locationImage}
                            alt={node2.locationTitle}
                          />
                          <h4 className="mb-2 font-weight-lighter">
                            {node2.locationTitle}
                          </h4>
                          <p className="text-muted mb-1">
                            <small>{node2.locationAddres1}</small>
                          </p>
                          <p className="text-muted mb-1">
                            <small>{node2.locationAddres2}</small>
                          </p>
                          <p className="text-muted mb-1">
                            <small>Phone: {node2.locationPhone}</small>
                          </p>
                          <p className="text-muted mb-1">
                            <small> Email:{node2.locationEmail}</small>
                          </p>
                          <div className="d-flex justify-content-center mt-3">
                            <a href={node2.locationCTALink} target="_blank">
                              <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                                {node2.locationCTAText}
                              </h6>
                            </a>
                            <img src="/img/arrow.svg" alt="arrow" />
                          </div>
                        </div>
                      ))}
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
      query {
        metadata: allCompanyJson(filter: {CompanyMetaTitle: {ne: null } }) {
        nodes {
        CompanyMetaDescription
        CompanyMetaTitle
      Companykeywords
      }
    }
      contactlocation: allContactJson(
      filter: {location: {elemMatch: {locationTitle: {ne: null } } } }
      ) {
        nodes {
        location {
        locationAddres1
          locationAddres2
      locationCTALink
      locationCTAText
      locationEmail
      locationImage
      locationPhone
      locationTitle
        }
      }
    }
      Hero: allCompanyJson(
      filter: {
        HeroCompany: {elemMatch: {CompanySectionTitle: {ne: null } } }
      }
      ) {
        nodes {
        HeroCompany {
        CompanySectionTitle
          CompanySectiondescription
      HeroSectionImage
        }
      }
    }
    valueshero: allCareersJson(filter: { valuesWidgetTitle: { ne: null } }) {
      nodes {
        values {
          valuesDescription
          valuesTitle
        }
        valuesWidgetCTALink
        valuesWidgetCTAText
        valuesWidgetIntro
        valuesWidgetTitle
      }
    }
      Services: allCompanyJson(
      filter: {ServiceCompanyWidgetTitle: {ne: null } }
      ) {
        nodes {
        ServiceCompanyWidgetCTALink
        ServiceCompanyWidgetTitle
      ServiceCompanyeWidgetCTAText
      ServiceCompanyeWidgetDescription
      }
    }
      History: allCompanyJson(filter: {historyTitle: {ne: null } }) {
        nodes {
        HistoryDate {
        HistorydateCTALink
          HistorydateCTAText
      HistorydateDescription
      HistoryDate
        }
      historyDescription
      historyIntro
      historyTitle
      }
    }
      Widget1: allCompanyJson(
      filter: {CompanyTextwithImageWidget1Title: {ne: null } }
      ) {
        nodes {
        CompanyTextwithImageWidget1TitlCTAText
        CompanyTextwithImageWidget1Title
      CompanyTextwithImageWidget1TitleCTALink
      CompanyTextwithImageWidget1TitleDescription
      CompanyTextwithImageWidget1TitleImage
      }
    }
      Widget2: allCompanyJson(
      filter: {CompanyTextwithImageWidget2Title: {ne: null } }
      ) {
        nodes {
        CompanyTextwithImageWidget2TitlCTAText
        CompanyTextwithImageWidget2Title
      CompanyTextwithImageWidget2TitleCTALink
      CompanyTextwithImageWidget2TitleDescription
      CompanyTextwithImageWidget2TitleImage
      }
    }
      president: allCompanyJson(filter: {PresidentBioTitle: {ne: null } }) {
        nodes {
        PresidentBioDescription
        PresidentBioImage
      PresidentBioRole
      PresidentBioTitle
      }
    }
    
    missionhero: allCareersJson(filter: { MissioneWidgetTitle: { ne: null } }) {
      nodes {
        MissionWidgetDescription
        MissionWidgetImage
        MissionWidgetImageDescription
        MissionWidgetImageTitle
        MissioneWidgetTitle
        MissioneWidgetintro
        MissionWidgetVisionImage
      }
    }
      Team: allCompanyJson(filter: {TeamTitle: {ne: null } }) {
        nodes {
        Members {
        MemberCategory
          MemberDescriptionDetail
      MemberImage
      MemberImageDetail
      MemberRole
      MemberTitle
        }
      TeamIntro
      TeamTitle
      }
    }
  }
      `
function DistinctRecords(MYJSON, prop) {
  return MYJSON.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}
