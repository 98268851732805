import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const MediaArticles = () => {
  const data = useStaticQuery(graphql`
    query {
      allLinksJson(limit: 1) {
        nodes {
          Category
          Title
          Image
          Intro
          CTALink
        }
      }
    }
  `)


  return (
    <>
      {data.allLinksJson.nodes.map((node, index) => {
        return (
          <>
            <div className="row text-left pb-4 align-items-center">
              <div className="col-md-8 text">
                <div className="parallelogram mb-4"><Link to={node.CTALink} className="text-dark" target="_blank">{node.Category}</Link></div>
                <Link to={node.CTALink} target="_blank" className="text-dark">
                <h5 className="font-weight-bolder">{node.Title}</h5>
                </Link>
                <p className="product-par pr-xs-0 mr-xs-0 text-truncate">
                  {node.Intro}
                </p>
              </div>
              <div className="col-md-4 img mb-4">
                <img src={node.Image} alt={node.Title} className="shadow-sm" />
                <Link to={node.CTALink} target="_blank" className="redirect">
                  <FontAwesomeIcon
                    className="font-size-sm"
                    icon={faChevronRight}
                  />
                </Link>
              </div>
            </div>
          </>
        )
      })}
    </>
  )

}
export default MediaArticles

// export page query
