import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const MediaNews = () => {
    const data = useStaticQuery(graphql`
  query  {
    allNewsJson(limit: 1) {
        nodes {
          Category
          Title
          Image
          Intro
          CTALink
        }
      }
  }
  
  `)
    return (
        <>

            {data.allNewsJson.nodes.map((node, index) => {
                return (
                    <>
                        <div className="row text-left pb-4 align-items-center" key={index}>
                            <div className="col-md-8 text">
                                <div className="parallelogram mb-4"><Link to={"/mediacenters/" + node.CTALink} className="text-dark">{node.Category}</Link></div>
                                <Link to={"/mediacenters/" + node.CTALink} className="text-dark">
                                    <h5 className="font-weight-bolder">
                                        {node.Title}
                                    </h5>
                                </Link>
                                <p className="product-par mr-xs-0 pr-xs-0 text-truncate">

                                    {node.Intro}
                                </p>
                            </div>
                            <div className="col-md-4 img mb-4">
                                <img src={node.Image} alt={node.Title} className="shadow-sm" />
                                <Link to={"/mediacenters/" + node.CTALink} className="redirect">
                                    <FontAwesomeIcon
                                        className="font-size-sm"
                                        icon={faChevronRight}
                                    />
                                </Link>
                            </div>
                        </div>

                    </>
                )

            })}

        </>)
}
export default MediaNews

// export page query
