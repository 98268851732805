
import React from "react"
import Slider from "react-slick"
import { Link, graphql, useStaticQuery } from 'gatsby'
function SampleNextArrow(props) {

  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>prev</span>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
    >
      <span>next</span>
    </div>
  )
}




export default function CarouselService(props) {

  const data = useStaticQuery(graphql`
    query  {
      allCompanyJson(filter: {historyTitle: {ne: null}}) {
        nodes {
          HistoryDate {
            HistorydateCTALink
            HistorydateCTAText
            HistorydateDescription
            HistoryDate
          }
        
        }
      }      
    }
    `)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

  }
  return (
    <div className="py-5 px-3 Timeline">
      <Slider {...settings}>
        {data.allCompanyJson.nodes.map((node) => {
          return node.HistoryDate
            ? node.HistoryDate.map((q, index) => (
              <>
                <div className="col-md-12 text-left pl-5 pl-md-0  pl-xs-0" key={index}>
                  <h6 className="font-weight-bolder Timeline-text">{new Date(q.HistoryDate).getFullYear()}</h6>
                  <p className="product-par mb-3">
                    <small>
                      {q.HistorydateDescription}
                    </small>
                  </p>
                  {q.HistorydateCTALink &&
                    <div to="/" className="parallelogram mb-4 bg-white">
                      <Link to={q.HistorydateCTALink} className="text-dark font-weight-bold">
                        {q.HistorydateCTAText}
                      </Link>
                    </div>
                  }
                </div>
              </>
            ))
            : null
        })}

      </Slider>
    </div>



  )
}




